<template>
  <div>
    <span v-if="colunaEditavel">
      <v-edit-dialog
        :return-value.sync="dadoAtualizado"
        large
        persistent
        cancel-text="Cancelar"
        save-text="Salvar"
        @save="save"
      >
        <div>{{ dadoAtualizado | treatDinamic(tipoDado) }}</div>
        <template v-slot:input>
          <div class="mt-4 text-h6">
            {{ coluna.toLowerCase() }} #{{ id | parseNumberToIntegerBR }}
          </div>
          <template v-if="colunaDicionarioDadosAneel">
            <input-dicionario-dados-aneel
              :label="null"
              :entidadeBdgd="entidadeBdgd"
              :campo="coluna"
              :dadoInicial="dadoAtualizado"
              @dadoAlterado="(data) => (dadoAtualizado = data)"
            />
          </template>
          <template v-else>
            <div v-if="tipoDado === 'inteiro'">
              <v-text-field
                v-model="dadoAtualizado"
                type="number"
                :step="1"
                autofocus
              />
            </div>
            <div v-else-if="['decimal', 'numeric'].includes(tipoDado)">
              <v-text-field
                v-model="dadoAtualizado"
                type="number"
                :step="0.01"
                autofocus
              />
            </div>
            <div v-else-if="tipoDado === 'data'">
              <input-date
                :label="null"
                :selectedDate="dadoAtualizado"
                :rules="{ required: true, min: 10 }"
                @dataSelected="(data) => (dadoAtualizado = data)"
              />
            </div>
            <div v-else>
              <v-text-field
                v-model="dadoAtualizado"
                single-line
                counter
                autofocus
              />
            </div>
          </template>
        </template>
      </v-edit-dialog>
    </span>
    <span v-else @click="notEditableClick">
      {{ dado | treatDinamic(tipoDado) }}
    </span>
  </div>
</template>

<script>
import EntidadesBdgdService from '@/services/EntidadesBdgdService';

export default {
  name: 'EditarDadoEntidade',
  components: {
    InputDate: () => import('@/components/general/InputDate.vue'),
    InputDicionarioDadosAneel: () =>
      import('@/components/general/InputDicionarioDadosAneel.vue')
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    entidadeBdgd: {
      type: String,
      required: true
    },
    coluna: {
      type: String,
      required: true
    },
    dado: {
      required: true
    },
    tipoDado: {
      type: String,
      required: true
    },
    edicaoHabilitada: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dadoAtualizado: null,
    colunasNaoEditaveis: [
      'gid',
      'latitude',
      'longitude',
      'latitude1',
      'longitude1',
      'latitude2',
      'longitude2'
    ]
  }),
  mounted() {
    this.dadoAtualizado = this.dado;
  },
  computed: {
    colunaEditavel() {
      return (
        this.edicaoHabilitada && !this.colunasNaoEditaveis.includes(this.coluna)
      );
    },
    colunaDicionarioDadosAneel() {
      return this.$store.getters.campoExiste(this.coluna);
    }
  },
  methods: {
    notEditableClick() {
      this.$emit('notEditableClick', {
        coluna: this.coluna,
      });
    },

    save() {
      EntidadesBdgdService.salvarDadoColuna(
        this.entidadeBdgd,
        this.id,
        this.coluna,
        this.dadoAtualizado,
        this.tipoDado
      )
        .then(() => {
          this.$toast.success(
            `Dado da coluna ${this.coluna}, na linha #${this.id}, salvo com sucesso.`,
            '',
            { position: 'topRight' }
          );
          this.$emit('update:dado-salvo', {
            id: this.id,
            coluna: this.coluna,
            dadoAtualizado: this.dadoAtualizado
          });
        })
        .catch((error) => {
          this.$toast.error(
            `Ocorreu um erro ao salvar o dado da coluna ${this.coluna}, na linha #${this.id}.`,
            '',
            { position: 'topRight' }
          );
          console.error('Erro: ', error);
        });
    }
  }
};
</script>
